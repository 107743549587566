<script lang="ts">
  import Link from '@src/components/Link.svelte'
  import { loggedInStore } from '@stores/logged-in-store'
</script>

<svelte:head>
  <title>Hirundo Management - Home</title>
  <meta name="description" content="Hirundo Management: This is the UI to manage Hirundo tools" />
</svelte:head>

<div class="mx-auto lg:text-center">
  <div class="grid w-full min-w-[44rem] grid-cols-[auto_1fr_auto] gap-1 md:min-w-[70vw]">
    <nav class="list-nav bg-surface-500/30 p-4">
      <ul>
        <Link path="/api-keys" listIcon>API keys</Link>
        <Link path="/organizations" listIcon>Organizations</Link>
        <Link path="/user-associations" listIcon>User associations</Link>
        <Link path="/invitations" listIcon>Invitations</Link>
      </ul>
    </nav>
    <section class="bg-surface-500/30 p-4">
      <h2 class="h2 mb-4 mt-2 font-medium tracking-tight">
        {$loggedInStore.fullName}, welcome to Hirundo!
      </h2>
      <p>Here is the place to manage your Hirundo account. Choose an item to Manage on the left</p>
    </section>
  </div>
</div>
